import { Union, Record } from "../fable_modules/fable-library.4.10.0/Types.js";
import { AnalyticsReport_$reflection, AnalyticsReportPage_$reflection, AnalyticsReportToken_$reflection, Account_$reflection } from "../../CView.DTOs/DTOs.fs.js";
import { record_type, class_type, unit_type, option_type, union_type, string_type, array_type } from "../fable_modules/fable-library.4.10.0/Reflection.js";
import { FSharpResult$2 } from "../fable_modules/fable-library.4.10.0/Choice.js";
import { AsyncMsg$1, Deferred$1, AsyncMsg$1_$reflection, Deferred$1_$reflection } from "../Extensions.fs.js";
import { exists, add, tryFind, remove, empty } from "../fable_modules/fable-library.4.10.0/Map.js";
import { createObj, equals, comparePrimitives } from "../fable_modules/fable-library.4.10.0/Util.js";
import { Cmd_batch, Cmd_none } from "../fable_modules/Fable.Elmish.4.0.2/cmd.fs.js";
import { ofArray, singleton } from "../fable_modules/fable-library.4.10.0/List.js";
import { Alert_text, Alert_title, Alert_error } from "../Components/SweetAlert.fs.js";
import { toArray, map as map_1, defaultArgWith, bind } from "../fable_modules/fable-library.4.10.0/Option.js";
import { map, sortBy, tryHead } from "../fable_modules/fable-library.4.10.0/Array.js";
import { Cmd_OfAsyncWith_perform } from "../fable_modules/Fable.Elmish.4.0.2/./cmd.fs.js";
import { startImmediate } from "../fable_modules/fable-library.4.10.0/Async.js";
import { getAnalyticsReports, getEmbedToken } from "../Api/PowerBIApi.fs.js";
import { PowerBI_render } from "../Components/PowerBI.fs.js";
import { createElement } from "react";
import { join } from "../fable_modules/fable-library.4.10.0/String.js";
import { Interop_reactApi } from "../fable_modules/Feliz.2.6.0/./Interop.fs.js";
import { map as map_2, exists as exists_1, empty as empty_1, append, singleton as singleton_1, collect, delay, toList } from "../fable_modules/fable-library.4.10.0/Seq.js";

export class State extends Record {
    constructor(Accounts, AccessTokenDeferred, SelectedReportPage, AvailableReports, SelectedReports) {
        super();
        this.Accounts = Accounts;
        this.AccessTokenDeferred = AccessTokenDeferred;
        this.SelectedReportPage = SelectedReportPage;
        this.AvailableReports = AvailableReports;
        this.SelectedReports = SelectedReports;
    }
}

export function State_$reflection() {
    return record_type("CView.UI.Pages.AnalyticsDashboard.State", [], State, () => [["Accounts", array_type(Account_$reflection())], ["AccessTokenDeferred", Deferred$1_$reflection(union_type("Microsoft.FSharp.Core.FSharpResult`2", [AnalyticsReportToken_$reflection(), string_type], FSharpResult$2, () => [[["ResultValue", AnalyticsReportToken_$reflection()]], [["ErrorValue", string_type]]]))], ["SelectedReportPage", option_type(AnalyticsReportPage_$reflection())], ["AvailableReports", Deferred$1_$reflection(union_type("Microsoft.FSharp.Core.FSharpResult`2", [array_type(AnalyticsReport_$reflection()), string_type], FSharpResult$2, () => [[["ResultValue", array_type(AnalyticsReport_$reflection())]], [["ErrorValue", string_type]]]))], ["SelectedReports", class_type("Microsoft.FSharp.Collections.FSharpMap`2", [string_type, unit_type])]]);
}

export class Msg extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["GetEmbedReportTokenAsync", "SelectReportPage", "GetUserAnalyticReportsAsync", "SetSelectedReport"];
    }
}

export function Msg_$reflection() {
    return union_type("CView.UI.Pages.AnalyticsDashboard.Msg", [], Msg, () => [[["Item", AsyncMsg$1_$reflection(union_type("Microsoft.FSharp.Core.FSharpResult`2", [AnalyticsReportToken_$reflection(), string_type], FSharpResult$2, () => [[["ResultValue", AnalyticsReportToken_$reflection()]], [["ErrorValue", string_type]]]))]], [["Item", AnalyticsReportPage_$reflection()]], [["Item", AsyncMsg$1_$reflection(union_type("Microsoft.FSharp.Core.FSharpResult`2", [array_type(AnalyticsReport_$reflection()), string_type], FSharpResult$2, () => [[["ResultValue", array_type(AnalyticsReport_$reflection())]], [["ErrorValue", string_type]]]))]], [["Item", string_type]]]);
}

function toUserAnalyticReports(state) {
    const matchValue = state.AvailableReports;
    let matchResult, analyticReports;
    switch (matchValue.tag) {
        case 2: {
            if (matchValue.fields[0].tag === 0) {
                matchResult = 1;
                analyticReports = matchValue.fields[0].fields[0];
            }
            else {
                matchResult = 0;
            }
            break;
        }
        default:
            matchResult = 0;
    }
    switch (matchResult) {
        case 0:
            return new Array(0);
        default:
            return analyticReports;
    }
}

export function init(accounts) {
    return [new State(accounts, new Deferred$1(0, []), void 0, new Deferred$1(0, []), empty({
        Compare: comparePrimitives,
    })), (accounts.length === 0) ? Cmd_none() : Cmd_batch(ofArray([singleton((dispatch) => {
        dispatch(new Msg(0, [new AsyncMsg$1(0, [])]));
    }), singleton((dispatch_1) => {
        dispatch_1(new Msg(2, [new AsyncMsg$1(0, [])]));
    })]))];
}

export function update(msg, state) {
    let availableReports, matchValue_2, selectedReport, firstReportPage, firstReportPage_1, matchValue, tokenResult;
    switch (msg.tag) {
        case 0:
            if (msg.fields[0].tag === 1) {
                return [new State(state.Accounts, new Deferred$1(2, [msg.fields[0].fields[0]]), state.SelectedReportPage, state.AvailableReports, state.SelectedReports), (msg.fields[0].fields[0].tag === 1) ? Alert_error(Alert_title("Analytics Dashboard", Alert_text(msg.fields[0].fields[0].fields[0]))) : ((availableReports = toUserAnalyticReports(state), (availableReports.length > 0) ? ((matchValue_2 = state.SelectedReportPage, (matchValue_2 != null) ? ((selectedReport = matchValue_2, singleton((dispatch_1) => {
                    dispatch_1(new Msg(1, [selectedReport]));
                }))) : ((firstReportPage = bind((firstReport) => tryHead(sortBy((x_3) => x_3.Ordinal, firstReport.Pages, {
                    Compare: comparePrimitives,
                })), tryHead(sortBy((x_1) => x_1.Ordinal, availableReports, {
                    Compare: comparePrimitives,
                }))), (firstReportPage != null) ? ((firstReportPage_1 = firstReportPage, singleton((dispatch) => {
                    dispatch(new Msg(1, [firstReportPage_1]));
                }))) : Cmd_none())))) : Cmd_none()))];
            }
            else if (state.AccessTokenDeferred.tag === 0) {
                return [new State(state.Accounts, new Deferred$1(1, []), state.SelectedReportPage, state.AvailableReports, state.SelectedReports), Cmd_OfAsyncWith_perform((computation) => {
                    startImmediate(computation);
                }, getEmbedToken, map((x) => x.Id, state.Accounts), (arg) => (new Msg(0, [new AsyncMsg$1(1, [arg])])))];
            }
            else {
                return [state, Cmd_none()];
            }
        case 2:
            if (msg.fields[0].tag === 1) {
                return [new State(state.Accounts, state.AccessTokenDeferred, state.SelectedReportPage, new Deferred$1(2, [msg.fields[0].fields[0]]), state.SelectedReports), (msg.fields[0].fields[0].tag === 1) ? Alert_error(Alert_title("Analytic Reports", Alert_text(msg.fields[0].fields[0].fields[0]))) : Cmd_none()];
            }
            else if (equals(state.AvailableReports, new Deferred$1(1, []))) {
                return [state, Cmd_none()];
            }
            else {
                return [new State(state.Accounts, state.AccessTokenDeferred, state.SelectedReportPage, new Deferred$1(1, []), state.SelectedReports), Cmd_OfAsyncWith_perform((computation_1) => {
                    startImmediate(computation_1);
                }, getAnalyticsReports, map((x_5) => x_5.Id, state.Accounts), (arg_2) => (new Msg(2, [new AsyncMsg$1(1, [arg_2])])))];
            }
        case 3:
            return [new State(state.Accounts, state.AccessTokenDeferred, state.SelectedReportPage, state.AvailableReports, defaultArgWith(map_1(() => remove(msg.fields[0], state.SelectedReports), tryFind(msg.fields[0], state.SelectedReports)), () => add(msg.fields[0], void 0, state.SelectedReports))), Cmd_none()];
        default:
            return [new State(state.Accounts, state.AccessTokenDeferred, msg.fields[0], state.AvailableReports, state.SelectedReports), (matchValue = state.AccessTokenDeferred, (matchValue.tag === 2) ? ((tokenResult = matchValue.fields[0], (tokenResult.tag === 1) ? Alert_error(Alert_title("Analytics Dashboard", Alert_text(tokenResult.fields[0]))) : PowerBI_render(msg.fields[0].ReportId, msg.fields[0].ReportId, tokenResult.fields[0].AccessToken, map((account) => account.ExternalId, state.Accounts), msg.fields[0].Name))) : Cmd_none())];
    }
}

export function render(state, dispatch) {
    let elems_7, elems_5, elems_4, elems_6;
    if (state.Accounts.length === 0) {
        const children = singleton(createElement("h4", {
            className: join(" ", ["has-text-weight-semibold", "has-text-centered", "is-size-5"]),
            children: "Please select a client account",
        }));
        return createElement("div", {
            children: Interop_reactApi.Children.toArray(Array.from(children)),
        });
    }
    else {
        return createElement("div", createObj(ofArray([["className", "columns"], (elems_7 = [createElement("div", createObj(ofArray([["className", join(" ", ["column", "is-2"])], (elems_5 = [createElement("aside", createObj(ofArray([["className", "menu"], (elems_4 = toList(delay(() => collect((report) => {
            let children_4, elems_1, elems, elems_3, elems_2;
            const isReportSelected = exists((key_5, _arg) => (key_5 === report.Id), state.SelectedReports);
            return singleton_1((children_4 = ofArray([createElement("div", createObj(ofArray([["className", join(" ", ["is-flex", "is-flex-direction-row", "is-flex-wrap-wrap"])], (elems_1 = [createElement("div", createObj(ofArray([["className", join(" ", ["is-flex", "is-align-items-center", "mb-2"])], (elems = [createElement("span", {
                className: join(" ", ["is-flex", "is-justify-content-flex-right", "material-icons", "is-clickable", "is-panel-collapsed", "mr-2"]),
                children: isReportSelected ? "expand_less" : "expand_more",
                onClick: (_arg_1) => {
                    dispatch(new Msg(3, [report.Id]));
                },
            }), createElement("p", {
                className: join(" ", ["menu-label", "m-0"]),
                style: {
                    fontSize: 14 + "px",
                },
                children: report.Name,
            })], ["children", Interop_reactApi.Children.toArray(Array.from(elems))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_1))])]))), createElement("div", createObj(ofArray([["className", join(" ", toList(delay(() => append(singleton_1("my-2"), delay(() => append(singleton_1("mx-4"), delay(() => (!isReportSelected ? singleton_1("is-hidden") : empty_1()))))))))], ["id", "expansion_panel_1"], (elems_3 = [createElement("ul", createObj(ofArray([["className", "menu-list"], (elems_2 = toList(delay(() => collect((page) => {
                let children_2;
                return singleton_1((children_2 = singleton(createElement("a", {
                    className: exists_1((selectedReport) => (selectedReport.Name === page.Name), toArray(state.SelectedReportPage)) ? "is-active" : "",
                    style: {
                        fontSize: 14 + "px",
                    },
                    children: page.Name,
                    onClick: (_arg_2) => {
                        dispatch(new Msg(1, [page]));
                    },
                })), createElement("li", {
                    children: Interop_reactApi.Children.toArray(Array.from(children_2)),
                })));
            }, sortBy((s) => s.Ordinal, report.Pages, {
                Compare: comparePrimitives,
            })))), ["children", Interop_reactApi.Children.toArray(Array.from(elems_2))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_3))])])))]), createElement("div", {
                children: Interop_reactApi.Children.toArray(Array.from(children_4)),
            })));
        }, sortBy((s_1) => s_1.Ordinal, toUserAnalyticReports(state), {
            Compare: comparePrimitives,
        })))), ["children", Interop_reactApi.Children.toArray(Array.from(elems_4))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_5))])]))), createElement("div", createObj(ofArray([["className", join(" ", ["column", "mb-2"])], ["style", {
            height: 80 + "vh",
        }], (elems_6 = toList(delay(() => map_2((report_1) => createElement("div", {
            id: report_1.Id,
            style: createObj(toList(delay(() => append(singleton_1(["height", 100 + "%"]), delay(() => {
                const matchValue = state.SelectedReportPage;
                let matchResult, selectedReport_2;
                if (matchValue != null) {
                    if (matchValue.ReportId === report_1.Id) {
                        matchResult = 0;
                        selectedReport_2 = matchValue;
                    }
                    else {
                        matchResult = 1;
                    }
                }
                else {
                    matchResult = 1;
                }
                switch (matchResult) {
                    case 0:
                        return singleton_1(["display", "block"]);
                    default:
                        return singleton_1(["display", "none"]);
                }
            }))))),
        }), toUserAnalyticReports(state)))), ["children", Interop_reactApi.Children.toArray(Array.from(elems_6))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_7))])])));
    }
}

