import { Union, Record } from "../fable_modules/fable-library.4.10.0/Types.js";
import { record_type, int32_type, array_type, option_type, lambda_type, class_type, union_type, string_type, bool_type } from "../fable_modules/fable-library.4.10.0/Reflection.js";
import { DataSource_$reflection } from "../Models.fs.js";
import { FSharpResult$2 } from "../fable_modules/fable-library.4.10.0/Choice.js";
import { AsyncMsg$1, Deferred$1, AsyncMsg$1_$reflection, Deferred$1_$reflection } from "../Extensions.fs.js";
import { Account_$reflection, AccountGroup_$reflection, GlobalAccount_$reflection } from "../../CView.DTOs/DTOs.fs.js";
import { toArray, defaultArg } from "../fable_modules/fable-library.4.10.0/Option.js";
import { ofArray, singleton } from "../fable_modules/fable-library.4.10.0/List.js";
import { Cmd_none } from "../fable_modules/Fable.Elmish.4.0.2/cmd.fs.js";
import { parse } from "../fable_modules/fable-library.4.10.0/Guid.js";
import { Toast_text, Toast_title, Toast_error } from "./SweetAlert.fs.js";
import { safeHash, createObj, equals } from "../fable_modules/fable-library.4.10.0/Util.js";
import { Cmd_OfAsyncWith_perform } from "../fable_modules/Fable.Elmish.4.0.2/./cmd.fs.js";
import { startImmediate } from "../fable_modules/fable-library.4.10.0/Async.js";
import { createElement } from "react";
import { printf, toText, join } from "../fable_modules/fable-library.4.10.0/String.js";
import { collect as collect_1, exists, map, empty, singleton as singleton_1, append, delay, toList } from "../fable_modules/fable-library.4.10.0/Seq.js";
import { Interop_reactApi } from "../fable_modules/Feliz.2.6.0/./Interop.fs.js";
import { render as render_1 } from "./Loader.fs.js";
import { defaultOf } from "../fable_modules/Feliz.2.6.0/../fable-library.4.10.0/Util.js";
import { where, append as append_1, collect } from "../fable_modules/fable-library.4.10.0/Array.js";
import { Array_distinct } from "../fable_modules/fable-library.4.10.0/Seq2.js";

export class State extends Record {
    constructor(IsModalActive, AccountGroupSearchCriteria, SearchAccountGroupsAsync, AccountGroupsDeferred, GetGlobalAccountAsync, GlobalAccountDeferred, SelectedAccountGroup, SelectedAccounts, TotalAccounts) {
        super();
        this.IsModalActive = IsModalActive;
        this.AccountGroupSearchCriteria = AccountGroupSearchCriteria;
        this.SearchAccountGroupsAsync = SearchAccountGroupsAsync;
        this.AccountGroupsDeferred = AccountGroupsDeferred;
        this.GetGlobalAccountAsync = GetGlobalAccountAsync;
        this.GlobalAccountDeferred = GlobalAccountDeferred;
        this.SelectedAccountGroup = SelectedAccountGroup;
        this.SelectedAccounts = SelectedAccounts;
        this.TotalAccounts = (TotalAccounts | 0);
    }
}

export function State_$reflection() {
    return record_type("CView.UI.AccountSearch.AccountSearch.State", [], State, () => [["IsModalActive", bool_type], ["AccountGroupSearchCriteria", string_type], ["SearchAccountGroupsAsync", lambda_type(string_type, class_type("Microsoft.FSharp.Control.FSharpAsync`1", [union_type("Microsoft.FSharp.Core.FSharpResult`2", [DataSource_$reflection(), string_type], FSharpResult$2, () => [[["ResultValue", DataSource_$reflection()]], [["ErrorValue", string_type]]])]))], ["AccountGroupsDeferred", Deferred$1_$reflection(union_type("Microsoft.FSharp.Core.FSharpResult`2", [DataSource_$reflection(), string_type], FSharpResult$2, () => [[["ResultValue", DataSource_$reflection()]], [["ErrorValue", string_type]]]))], ["GetGlobalAccountAsync", lambda_type(class_type("System.Guid"), class_type("Microsoft.FSharp.Control.FSharpAsync`1", [union_type("Microsoft.FSharp.Core.FSharpResult`2", [GlobalAccount_$reflection(), string_type], FSharpResult$2, () => [[["ResultValue", GlobalAccount_$reflection()]], [["ErrorValue", string_type]]])]))], ["GlobalAccountDeferred", Deferred$1_$reflection(union_type("Microsoft.FSharp.Core.FSharpResult`2", [GlobalAccount_$reflection(), string_type], FSharpResult$2, () => [[["ResultValue", GlobalAccount_$reflection()]], [["ErrorValue", string_type]]]))], ["SelectedAccountGroup", option_type(AccountGroup_$reflection())], ["SelectedAccounts", array_type(Account_$reflection())], ["TotalAccounts", int32_type]]);
}

export class Intent extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["SelectedAccountsChanged", "NoOp"];
    }
}

export function Intent_$reflection() {
    return union_type("CView.UI.AccountSearch.AccountSearch.Intent", [], Intent, () => [[["Item1", array_type(Account_$reflection())], ["Item2", int32_type]], []]);
}

export class Msg extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["SetAccountGroupSearchCriteria", "SetSelectedAccountGroup", "SetSelectedAccounts", "SearchAccountGroups", "GetGlobalAccount", "Cancel", "Select", "SetTotalAccountsToSelect"];
    }
}

export function Msg_$reflection() {
    return union_type("CView.UI.AccountSearch.AccountSearch.Msg", [], Msg, () => [[["searchCriteria", string_type]], [["Item", option_type(class_type("CView.UI.Models.IDataSourceItem"))]], [["Item", array_type(Account_$reflection())]], [["Item", AsyncMsg$1_$reflection(union_type("Microsoft.FSharp.Core.FSharpResult`2", [DataSource_$reflection(), string_type], FSharpResult$2, () => [[["ResultValue", DataSource_$reflection()]], [["ErrorValue", string_type]]]))]], [["Item", AsyncMsg$1_$reflection(union_type("Microsoft.FSharp.Core.FSharpResult`2", [GlobalAccount_$reflection(), string_type], FSharpResult$2, () => [[["ResultValue", GlobalAccount_$reflection()]], [["ErrorValue", string_type]]]))]], [], [], [["Item", int32_type]]]);
}

export function init(accountGroup, accounts, totalAccounts, searchAccountGroupsAsync, getGlobalAccountAsync) {
    let x_2;
    return [new State(accountGroup == null, defaultArg((x_2 = accountGroup, (x_2 == null) ? void 0 : x_2.Name), ""), searchAccountGroupsAsync, new Deferred$1(0, []), getGlobalAccountAsync, new Deferred$1(0, []), accountGroup, accounts, totalAccounts), singleton((dispatch) => {
        dispatch(new Msg(3, [new AsyncMsg$1(0, [])]));
    })];
}

export function update(msg, state) {
    let s2_1, s1, s2, x_2, x, input_1, input_3, x_6;
    switch (msg.tag) {
        case 1: {
            const command = (msg.fields[0] != null) ? singleton((dispatch) => {
                dispatch(new Msg(4, [new AsyncMsg$1(0, [])]));
            }) : Cmd_none();
            let isCurrentAccountGroup;
            const matchValue = state.SelectedAccountGroup;
            isCurrentAccountGroup = ((matchValue == null) ? ((msg.fields[0] == null) ? true : true) : ((msg.fields[0] == null) ? ((s2_1 = matchValue, false)) : ((s1 = matchValue, (s2 = msg.fields[0], s1.Id === parse(s2.Id))))));
            return [new State(state.IsModalActive, state.AccountGroupSearchCriteria, state.SearchAccountGroupsAsync, state.AccountGroupsDeferred, state.GetGlobalAccountAsync, new Deferred$1(0, []), (x_2 = msg.fields[0], (x_2 == null) ? void 0 : ((x = x_2, x.Value))), isCurrentAccountGroup ? state.SelectedAccounts : (new Array(0)), state.TotalAccounts), command, new Intent(1, [])];
        }
        case 2:
            return [new State(state.IsModalActive, state.AccountGroupSearchCriteria, state.SearchAccountGroupsAsync, state.AccountGroupsDeferred, state.GetGlobalAccountAsync, state.GlobalAccountDeferred, state.SelectedAccountGroup, msg.fields[0], state.TotalAccounts), Cmd_none(), new Intent(1, [])];
        case 5:
            return [new State(false, state.AccountGroupSearchCriteria, state.SearchAccountGroupsAsync, state.AccountGroupsDeferred, state.GetGlobalAccountAsync, state.GlobalAccountDeferred, void 0, new Array(0), state.TotalAccounts), Cmd_none(), new Intent(1, [])];
        case 6:
            return [new State(false, state.AccountGroupSearchCriteria, state.SearchAccountGroupsAsync, state.AccountGroupsDeferred, state.GetGlobalAccountAsync, state.GlobalAccountDeferred, state.SelectedAccountGroup, state.SelectedAccounts, state.TotalAccounts), Cmd_none(), new Intent(0, [state.SelectedAccounts, state.TotalAccounts])];
        case 3:
            if (msg.fields[0].tag === 1) {
                return [new State(state.IsModalActive, state.AccountGroupSearchCriteria, state.SearchAccountGroupsAsync, new Deferred$1(2, [msg.fields[0].fields[0]]), state.GetGlobalAccountAsync, state.GlobalAccountDeferred, state.SelectedAccountGroup, state.SelectedAccounts, state.TotalAccounts), (input_1 = msg.fields[0].fields[0], (input_1.tag === 1) ? Toast_error(Toast_title("Account Groups", Toast_text(input_1.fields[0]))) : Cmd_none()), new Intent(1, [])];
            }
            else if (equals(state.AccountGroupsDeferred, new Deferred$1(1, []))) {
                return [state, Cmd_none(), new Intent(1, [])];
            }
            else if (!state.IsModalActive) {
                return [state, Cmd_none(), new Intent(1, [])];
            }
            else {
                return [new State(state.IsModalActive, state.AccountGroupSearchCriteria, state.SearchAccountGroupsAsync, new Deferred$1(1, []), state.GetGlobalAccountAsync, new Deferred$1(0, []), void 0, state.SelectedAccounts, state.TotalAccounts), Cmd_OfAsyncWith_perform((computation) => {
                    startImmediate(computation);
                }, state.SearchAccountGroupsAsync, state.AccountGroupSearchCriteria, (arg) => (new Msg(3, [new AsyncMsg$1(1, [arg])]))), new Intent(1, [])];
            }
        case 4:
            if (msg.fields[0].tag === 1) {
                return [new State(state.IsModalActive, state.AccountGroupSearchCriteria, state.SearchAccountGroupsAsync, state.AccountGroupsDeferred, state.GetGlobalAccountAsync, new Deferred$1(2, [msg.fields[0].fields[0]]), state.SelectedAccountGroup, state.SelectedAccounts, state.TotalAccounts), (input_3 = msg.fields[0].fields[0], (input_3.tag === 1) ? Toast_error(Toast_title("Global Account", Toast_text(input_3.fields[0]))) : Cmd_none()), new Intent(1, [])];
            }
            else if (equals(state.GlobalAccountDeferred, new Deferred$1(1, []))) {
                return [state, Cmd_none(), new Intent(1, [])];
            }
            else if (!state.IsModalActive) {
                return [state, Cmd_none(), new Intent(1, [])];
            }
            else {
                return [new State(state.IsModalActive, state.AccountGroupSearchCriteria, state.SearchAccountGroupsAsync, state.AccountGroupsDeferred, state.GetGlobalAccountAsync, new Deferred$1(1, []), state.SelectedAccountGroup, state.SelectedAccounts, state.TotalAccounts), Cmd_OfAsyncWith_perform((computation_1) => {
                    startImmediate(computation_1);
                }, state.GetGlobalAccountAsync, defaultArg((x_6 = state.SelectedAccountGroup, (x_6 == null) ? void 0 : x_6.Id), "00000000-0000-0000-0000-000000000000"), (arg_2) => (new Msg(4, [new AsyncMsg$1(1, [arg_2])]))), new Intent(1, [])];
            }
        case 7:
            return [new State(state.IsModalActive, state.AccountGroupSearchCriteria, state.SearchAccountGroupsAsync, state.AccountGroupsDeferred, state.GetGlobalAccountAsync, state.GlobalAccountDeferred, state.SelectedAccountGroup, state.SelectedAccounts, msg.fields[0]), Cmd_none(), new Intent(1, [])];
        default:
            return [new State(state.IsModalActive, msg.fields[0], state.SearchAccountGroupsAsync, state.AccountGroupsDeferred, state.GetGlobalAccountAsync, state.GlobalAccountDeferred, state.SelectedAccountGroup, state.SelectedAccounts, state.TotalAccounts), Cmd_none(), new Intent(1, [])];
    }
}

export function render(state, dispatch) {
    let elems_21, elems_20, elems, elems_18, elems_6, elems_5, elems_4, elems_1, value_26, elems_3, elems_2, elems_7, matchValue_1, arg_1, elems_11, elems_10, elems_9, elems_17, elems_16, elems_15, elems_19;
    return createElement("div", createObj(ofArray([["className", join(" ", toList(delay(() => append(singleton_1("modal"), delay(() => append(singleton_1("px-4"), delay(() => (state.IsModalActive ? singleton_1("is-active") : empty()))))))))], (elems_21 = [createElement("div", {
        className: "modal-background",
    }), createElement("div", createObj(ofArray([["className", join(" ", ["modal-card", "pb-6"])], (elems_20 = [createElement("header", createObj(ofArray([["className", "modal-card-head"], (elems = [createElement("p", {
        className: join(" ", ["modal-card-title", "is-size-6"]),
        children: "Select client account",
    }), createElement("span", {
        className: join(" ", ["material-icons"]),
        children: "check",
    })], ["children", Interop_reactApi.Children.toArray(Array.from(elems))])]))), createElement("section", createObj(ofArray([["className", "modal-card-body"], (elems_18 = [createElement("div", createObj(ofArray([["className", "columns"], (elems_6 = [createElement("div", createObj(ofArray([["className", join(" ", ["column"])], (elems_5 = [createElement("div", createObj(ofArray([["className", join(" ", ["field", "has-addons", "has-addons-right"])], (elems_4 = [createElement("p", createObj(ofArray([["className", join(" ", ["control", "is-expanded"])], (elems_1 = [createElement("input", createObj(ofArray([["className", join(" ", ["input", "is-small"])], ["type", "text"], ["maxLength", 256], ["placeholder", "Search global accounts..."], (value_26 = state.AccountGroupSearchCriteria, ["ref", (e) => {
        if (!(e == null) && !equals(e.value, value_26)) {
            e.value = value_26;
        }
    }]), ["onChange", (ev) => {
        dispatch(new Msg(0, [ev.target.value]));
    }], ["onKeyPress", (keyPress) => {
        if (keyPress.key === "Enter") {
            dispatch(new Msg(3, [new AsyncMsg$1(0, [])]));
        }
    }]])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_1))])]))), createElement("p", createObj(ofArray([["className", join(" ", ["control"])], (elems_3 = [createElement("button", createObj(ofArray([["className", join(" ", ["button", "is-small"])], ["type", "button"], ["onClick", (_arg) => {
        dispatch(new Msg(3, [new AsyncMsg$1(0, [])]));
    }], (elems_2 = [createElement("span", {
        className: join(" ", ["material-icons"]),
        children: "search",
    }), createElement("span", {
        className: "is-sr-only",
        children: "Search",
    })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_2))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_3))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_4))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_5))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_6))])]))), createElement("div", createObj(ofArray([["className", join(" ", ["columns"])], (elems_7 = [createElement("span", {
        className: join(" ", ["column", "pt-0", "pb-0", "is-size-7"]),
        children: (matchValue_1 = state.AccountGroupsDeferred, (matchValue_1.tag === 1) ? "" : ((matchValue_1.tag === 2) ? ((matchValue_1.fields[0].tag === 0) ? (matchValue_1.fields[0].fields[0].IsPaginated ? ((arg_1 = (matchValue_1.fields[0].fields[0].Data.length | 0), toText(printf("Showing top %i global accounts"))(arg_1))) : "") : "") : "")),
    })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_7))])]))), createElement("div", createObj(ofArray([["className", "table-container"], ["style", {
        maxHeight: 200,
        overflowY: "auto",
    }], (elems_11 = [createElement("table", createObj(ofArray([["className", join(" ", ["table", "is-bordered", "is-narrow", "is-hoverable", "is-fullwidth"])], (elems_10 = [createElement("tbody", createObj(singleton((elems_9 = toList(delay(() => {
        const matchValue_2 = state.AccountGroupsDeferred;
        return (matchValue_2.tag === 1) ? singleton_1(render_1()) : ((matchValue_2.tag === 2) ? ((matchValue_2.fields[0].tag === 0) ? map((accountGroup) => {
            let elems_8;
            return createElement("tr", createObj(ofArray([["className", join(" ", toList(delay(() => append(singleton_1("is-clickable"), delay(() => (exists((x) => (x.Id === accountGroup.Id), toArray(state.SelectedAccountGroup)) ? singleton_1("has-background-info-light") : empty()))))))], (elems_8 = [createElement("td", {
                className: join(" ", ["is-size-7", "p-2"]),
                children: accountGroup.DisplayValue,
                onClick: (_arg_1) => {
                    dispatch(new Msg(1, [accountGroup]));
                },
            })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_8))])])));
        }, matchValue_2.fields[0].fields[0].Data) : singleton_1(defaultOf())) : singleton_1(defaultOf()));
    })), ["children", Interop_reactApi.Children.toArray(Array.from(elems_9))]))))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_10))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_11))])]))), createElement("hr", {}), createElement("div", createObj(ofArray([["className", join(" ", ["columns"])], (elems_17 = [createElement("div", createObj(ofArray([["className", join(" ", ["column"])], (elems_16 = [createElement("div", createObj(ofArray([["className", join(" ", ["is-bordered", "p-2"])], (elems_15 = toList(delay(() => {
        let elems_12;
        const matchValue_3 = state.GlobalAccountDeferred;
        switch (matchValue_3.tag) {
            case 1:
                return singleton_1(render_1());
            case 2:
                if (matchValue_3.fields[0].tag === 0) {
                    const parentAccounts = matchValue_3.fields[0].fields[0].Accounts;
                    const childAccounts = collect((x_1) => x_1.Accounts, parentAccounts);
                    return append(singleton_1(createElement("div", createObj(ofArray([["className", join(" ", ["is-flex", "is-align-items-center", "my-1"])], (elems_12 = [createElement("input", {
                        style: {
                            minWidth: 20 + "px",
                        },
                        className: join(" ", ["is-clickable", "mr-3"]),
                        id: matchValue_3.fields[0].fields[0].Id,
                        name: matchValue_3.fields[0].fields[0].Id,
                        type: "checkbox",
                        checked: childAccounts.every((account) => state.SelectedAccounts.some((ads) => (ads.Id === account.Id))),
                        onChange: (ev_1) => {
                            dispatch(new Msg(2, [ev_1.target.checked ? childAccounts : (new Array(0))]));
                            dispatch(new Msg(7, [childAccounts.length]));
                        },
                    }), createElement("label", {
                        className: join(" ", ["is-clickable", "mr-3", "is-size-7"]),
                        for: matchValue_3.fields[0].fields[0].Id,
                        children: matchValue_3.fields[0].fields[0].Name,
                    })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_12))])])))), delay(() => collect_1((parentAccount) => {
                        let elems_13;
                        return append(singleton_1(createElement("div", createObj(ofArray([["className", join(" ", ["is-flex", "is-align-items-center", "my-1", "ml-5"])], (elems_13 = [createElement("input", {
                            style: {
                                minWidth: 20 + "px",
                            },
                            className: join(" ", ["is-clickable", "mr-3"]),
                            id: parentAccount.Id,
                            name: parentAccount.Id,
                            type: "checkbox",
                            checked: parentAccount.Accounts.every((account_1) => state.SelectedAccounts.some((ads_1) => (ads_1.Id === account_1.Id))),
                            onChange: (ev_2) => {
                                dispatch(new Msg(2, [ev_2.target.checked ? append_1(parentAccount.Accounts, state.SelectedAccounts) : where((ads_2) => !parentAccount.Accounts.some((x_7) => (x_7.Id === ads_2.Id)), state.SelectedAccounts)]));
                                dispatch(new Msg(7, [childAccounts.length]));
                            },
                        }), createElement("label", {
                            className: join(" ", ["is-clickable", "mr-3", "is-size-7"]),
                            for: parentAccount.Id,
                            children: parentAccount.Name,
                        })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_13))])])))), delay(() => map((account_2) => {
                            let elems_14;
                            return createElement("div", createObj(ofArray([["className", join(" ", ["is-flex", "is-align-items-center", "my-1", "ml-6"])], (elems_14 = [createElement("input", {
                                style: {
                                    minWidth: 20 + "px",
                                },
                                className: join(" ", ["is-clickable", "mr-3"]),
                                id: account_2.Id,
                                name: account_2.Id,
                                type: "checkbox",
                                checked: state.SelectedAccounts.some((ads_3) => (ads_3.Id === account_2.Id)),
                                onChange: (ev_3) => {
                                    dispatch(new Msg(2, [ev_3.target.checked ? append_1([account_2], state.SelectedAccounts) : where((x_13) => (x_13.Id !== account_2.Id), state.SelectedAccounts)]));
                                    dispatch(new Msg(7, [childAccounts.length]));
                                },
                            }), createElement("label", {
                                className: join(" ", ["is-clickable", "mr-3", "is-size-7"]),
                                for: account_2.Id,
                                children: account_2.Name,
                            })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_14))])])));
                        }, Array_distinct(where((x_9) => (x_9.AccountGroupId === parentAccount.Id), childAccounts), {
                            Equals: equals,
                            GetHashCode: safeHash,
                        }))));
                    }, parentAccounts)));
                }
                else {
                    return singleton_1(defaultOf());
                }
            default:
                return singleton_1(createElement("div", {
                    className: join(" ", ["is-size-7", "p-4"]),
                    children: "No global account selected",
                }));
        }
    })), ["children", Interop_reactApi.Children.toArray(Array.from(elems_15))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_16))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_17))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_18))])]))), createElement("footer", createObj(ofArray([["className", join(" ", ["modal-card-foot", "is-justify-content-center"])], (elems_19 = [createElement("button", {
        className: join(" ", ["button", "is-small", "is-fullwidth", "is-primary"]),
        type: "button",
        children: "Select",
        disabled: state.SelectedAccounts.length === 0,
        onClick: (_arg_2) => {
            dispatch(new Msg(6, []));
        },
    }), createElement("button", {
        className: join(" ", ["button", "is-small", "is-fullwidth"]),
        type: "button",
        children: "Cancel",
        onClick: (_arg_3) => {
            dispatch(new Msg(5, []));
        },
    })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_19))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_20))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_21))])])));
}

