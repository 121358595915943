import { toString, Union, Record } from "../fable_modules/fable-library.4.10.0/Types.js";
import { BookingSearchQuery_get_Default, BookingSearchQuery as BookingSearchQuery_6, UserDataSources_$reflection, PagedDataResponse$1_$reflection, BookingSearchItem_$reflection, BookingSearchQuery_$reflection, User_$reflection, Account_$reflection } from "../../CView.DTOs/DTOs.fs.js";
import { int32_type, option_type, class_type, bool_type, lambda_type, record_type, unit_type, union_type, string_type, array_type } from "../fable_modules/fable-library.4.10.0/Reflection.js";
import { FSharpResult$2 } from "../fable_modules/fable-library.4.10.0/Choice.js";
import { DateTime_formatNullable, AsyncMsg$1, Deferred$1, Deferred_exists, AsyncMsg$1_$reflection, Deferred$1_$reflection } from "../Extensions.fs.js";
import { render as render_1, update as update_1, State as State_1, init as init_1, Msg_$reflection as Msg_$reflection_1, State_$reflection as State_$reflection_1 } from "../Components/SelectSearch.fs.js";
import { toInt32, equals, fromInt32, toInt64, compare } from "../fable_modules/fable-library.4.10.0/BigInt.js";
import { defaultOf as defaultOf_1, createObj, comparePrimitives, equals as equals_1 } from "../fable_modules/fable-library.4.10.0/Util.js";
import { map, sortBy } from "../fable_modules/fable-library.4.10.0/Array.js";
import { getShippers, getConsignees, getLocations } from "../Api/DataSourceApi.fs.js";
import { SearchType_Entity, Location_LocationType, Location_Entity } from "../../CView.DTOs/Constants.fs.js";
import { Cmd_none, Cmd_map, Cmd_batch } from "../fable_modules/Fable.Elmish.4.0.2/cmd.fs.js";
import { ofArray, singleton } from "../fable_modules/fable-library.4.10.0/List.js";
import { Alert_text, Alert_title, Alert_error } from "../Components/SweetAlert.fs.js";
import { Cmd_OfAsyncWith_perform } from "../fable_modules/Fable.Elmish.4.0.2/./cmd.fs.js";
import { startImmediate } from "../fable_modules/fable-library.4.10.0/Async.js";
import { export$, search } from "../Api/BookingApi.fs.js";
import { toNullable, value as value_507, some, defaultArg } from "../fable_modules/fable-library.4.10.0/Option.js";
import { defaultOf } from "../fable_modules/FsToolkit.ErrorHandling.4.9.0/../../Pages/../fable_modules/fable-library.4.10.0/Util.js";
import { DateTimeOffset_fromString, String_defaultValue } from "../../CView.DTOs/PrimitiveTypes.fs.js";
import { createElement } from "react";
import { compare as compare_1, isNullOrWhiteSpace, join } from "../fable_modules/fable-library.4.10.0/String.js";
import { Interop_reactApi } from "../fable_modules/Feliz.2.6.0/./Interop.fs.js";
import { formatBookingStatusAsClass, formatDecimalWithUnits, toDateTimeOffsetInput, formatInt } from "../Domain/Formatter.fs.js";
import { map as map_1, empty, singleton as singleton_1, append, delay, toList } from "../fable_modules/fable-library.4.10.0/Seq.js";
import { maxValue, minValue, toString as toString_1 } from "../fable_modules/fable-library.4.10.0/Date.js";
import { render as render_2 } from "../Components/Loader.fs.js";
import { RouterModule_encodeParts } from "../fable_modules/Feliz.Router.4.0.0/./Router.fs.js";
import { IconType, ModeOfTransportIcon_render } from "../Components/ModeOfTransportIcon.fs.js";
import { defaultOf as defaultOf_2 } from "../fable_modules/Feliz.2.6.0/../fable-library.4.10.0/Util.js";

export class State extends Record {
    constructor(Accounts, User, BookingSearchQuery, PreviousBookingSearchQuery, Bookings, UserDataSources, OriginSearch, DestinationSearch, ConsigneeSearch, ShipperSearch, ExportBookings) {
        super();
        this.Accounts = Accounts;
        this.User = User;
        this.BookingSearchQuery = BookingSearchQuery;
        this.PreviousBookingSearchQuery = PreviousBookingSearchQuery;
        this.Bookings = Bookings;
        this.UserDataSources = UserDataSources;
        this.OriginSearch = OriginSearch;
        this.DestinationSearch = DestinationSearch;
        this.ConsigneeSearch = ConsigneeSearch;
        this.ShipperSearch = ShipperSearch;
        this.ExportBookings = ExportBookings;
    }
}

export function State_$reflection() {
    return record_type("CView.UI.Pages.Bookings.State", [], State, () => [["Accounts", array_type(Account_$reflection())], ["User", User_$reflection()], ["BookingSearchQuery", BookingSearchQuery_$reflection()], ["PreviousBookingSearchQuery", BookingSearchQuery_$reflection()], ["Bookings", Deferred$1_$reflection(union_type("Microsoft.FSharp.Core.FSharpResult`2", [PagedDataResponse$1_$reflection(BookingSearchItem_$reflection()), string_type], FSharpResult$2, () => [[["ResultValue", PagedDataResponse$1_$reflection(BookingSearchItem_$reflection())]], [["ErrorValue", string_type]]]))], ["UserDataSources", UserDataSources_$reflection()], ["OriginSearch", State_$reflection_1()], ["DestinationSearch", State_$reflection_1()], ["ConsigneeSearch", State_$reflection_1()], ["ShipperSearch", State_$reflection_1()], ["ExportBookings", Deferred$1_$reflection(union_type("Microsoft.FSharp.Core.FSharpResult`2", [unit_type, string_type], FSharpResult$2, () => [[["ResultValue", unit_type]], [["ErrorValue", string_type]]]))]]);
}

export class Intent extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["BookingQueryChanged", "NoOp"];
    }
}

export function Intent_$reflection() {
    return union_type("CView.UI.Pages.Bookings.Intent", [], Intent, () => [[["Item", BookingSearchQuery_$reflection()]], []]);
}

export class Msg extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["UpdateBookingQuery", "SearchBookingsAsync", "OriginSearchMsg", "SetSelectedOrigin", "DestinationSearchMsg", "SetSelectedDestination", "ConsigneeSearchMsg", "SetSelectedConsignee", "ShipperSearchMsg", "SetSelectedShipper", "ClearSearch", "ExportBookings", "SetPageNumber"];
    }
}

export function Msg_$reflection() {
    return union_type("CView.UI.Pages.Bookings.Msg", [], Msg, () => [[["Item", lambda_type(BookingSearchQuery_$reflection(), BookingSearchQuery_$reflection())]], [["Item1", AsyncMsg$1_$reflection(union_type("Microsoft.FSharp.Core.FSharpResult`2", [PagedDataResponse$1_$reflection(BookingSearchItem_$reflection()), string_type], FSharpResult$2, () => [[["ResultValue", PagedDataResponse$1_$reflection(BookingSearchItem_$reflection())]], [["ErrorValue", string_type]]]))], ["Item2", bool_type]], [["Item", Msg_$reflection_1()]], [["Item", option_type(class_type("CView.UI.Models.IDataSourceItem"))]], [["Item", Msg_$reflection_1()]], [["Item", option_type(class_type("CView.UI.Models.IDataSourceItem"))]], [["Item", Msg_$reflection_1()]], [["Item", option_type(class_type("CView.UI.Models.IDataSourceItem"))]], [["Item", Msg_$reflection_1()]], [["Item", option_type(class_type("CView.UI.Models.IDataSourceItem"))]], [], [["Item", AsyncMsg$1_$reflection(union_type("Microsoft.FSharp.Core.FSharpResult`2", [unit_type, string_type], FSharpResult$2, () => [[["ResultValue", unit_type]], [["ErrorValue", string_type]]]))]], [["Item", int32_type]]]);
}

function hasPreviousPage(state) {
    return Deferred_exists((result) => {
        if (result.tag === 0) {
            return result.fields[0].Page > 1;
        }
        else {
            return false;
        }
    }, state.Bookings);
}

function hasNextPage(state) {
    return Deferred_exists((result) => {
        if (result.tag === 0) {
            const pagedData = result.fields[0];
            return compare(toInt64(fromInt32(pagedData.Page * pagedData.PageSize)), pagedData.TotalCount) < 0;
        }
        else {
            return false;
        }
    }, state.Bookings);
}

function isExportDisabled(state) {
    if (Deferred_exists((result) => {
        if (result.tag === 0) {
            return equals(result.fields[0].TotalCount, toInt64(fromInt32(0)));
        }
        else {
            return false;
        }
    }, state.Bookings)) {
        return true;
    }
    else {
        return equals_1(state.Bookings, new Deferred$1(1, []));
    }
}

function serviceLevelSearch(state) {
    const serviceLevels = sortBy((x) => x.Name, state.UserDataSources.ServiceLevels, {
        Compare: comparePrimitives,
    });
    const matchValue = state.BookingSearchQuery.ModeOfTransport;
    switch (matchValue) {
        case "Air":
            return serviceLevels.filter((x_2) => x_2.IsAir);
        case "Ground":
            return serviceLevels.filter((x_3) => x_3.IsGround);
        case "Ocean":
            return serviceLevels.filter((x_4) => x_4.IsOcean);
        default:
            return new Array(0);
    }
}

export function init(accounts, user, bookingSearchQuery, userDataSources) {
    const accountIds = map((x) => x.Id, accounts);
    const patternInput = init_1("Origin", bookingSearchQuery.PlaceOfReceipt, (searchCriteria) => getLocations(new Location_Entity(1, []), new Location_LocationType(0, []), accountIds, searchCriteria));
    const patternInput_1 = init_1("Destination", bookingSearchQuery.Destination, (searchCriteria_1) => getLocations(new Location_Entity(1, []), new Location_LocationType(1, []), accountIds, searchCriteria_1));
    const patternInput_2 = init_1("Consignee", bookingSearchQuery.ConsigneeName, (searchCriteria_2) => getConsignees(new SearchType_Entity(1, []), accountIds, searchCriteria_2));
    const patternInput_3 = init_1("Shipper", bookingSearchQuery.ShipperName, (searchCriteria_3) => getShippers(new SearchType_Entity(1, []), accountIds, searchCriteria_3));
    return [new State(accounts, user, bookingSearchQuery, bookingSearchQuery, new Deferred$1(0, []), userDataSources, patternInput[0], patternInput_1[0], patternInput_2[0], patternInput_3[0], new Deferred$1(0, [])), Cmd_batch(ofArray([singleton((dispatch) => {
        dispatch(new Msg(1, [new AsyncMsg$1(0, []), false]));
    }), Cmd_map((Item) => (new Msg(2, [Item])), patternInput[1]), Cmd_map((Item_1) => (new Msg(4, [Item_1])), patternInput_1[1]), Cmd_map((Item_2) => (new Msg(6, [Item_2])), patternInput_2[1]), Cmd_map((Item_3) => (new Msg(8, [Item_3])), patternInput_3[1])]))];
}

export function update(msg, state) {
    let bind$0040, bind$0040_1, bind$0040_2, bind$0040_3, bind$0040_4, bind$0040_5, bind$0040_6, x_6, x_4, x_2, value_2, bind$0040_7, x_11, x_9, x_7, value_6, bind$0040_8, x_14, x_12, bind$0040_9, x_17, x_15, bind$0040_10, bind$0040_11;
    switch (msg.tag) {
        case 1:
            if (msg.fields[0].tag === 1) {
                return [new State(state.Accounts, state.User, state.BookingSearchQuery, msg.fields[1] ? state.PreviousBookingSearchQuery : state.BookingSearchQuery, new Deferred$1(2, [msg.fields[0].fields[0]]), state.UserDataSources, state.OriginSearch, state.DestinationSearch, state.ConsigneeSearch, state.ShipperSearch, state.ExportBookings), (msg.fields[0].fields[0].tag === 1) ? Alert_error(Alert_title("Search Bookings", Alert_text(msg.fields[0].fields[0].fields[0]))) : Cmd_none(), new Intent(1, [])];
            }
            else if (equals_1(state.Bookings, new Deferred$1(1, [])) ? true : (state.Accounts.length === 0)) {
                return [state, Cmd_none(), new Intent(1, [])];
            }
            else {
                return [new State(state.Accounts, state.User, state.BookingSearchQuery, state.PreviousBookingSearchQuery, new Deferred$1(1, []), state.UserDataSources, state.OriginSearch, state.DestinationSearch, state.ConsigneeSearch, state.ShipperSearch, state.ExportBookings), Cmd_OfAsyncWith_perform((computation) => {
                    startImmediate(computation);
                }, search, msg.fields[1] ? ((bind$0040 = state.PreviousBookingSearchQuery, new BookingSearchQuery_6(map((x) => x.Id, state.Accounts), bind$0040.BookingNumber, bind$0040.BookingStatus, bind$0040.PlaceOfReceipt, bind$0040.Destination, bind$0040.BookingDateName, bind$0040.FromDate, bind$0040.ToDate, bind$0040.ShipperName, bind$0040.ConsigneeName, bind$0040.ModeOfTransport, bind$0040.ServiceLevel, bind$0040.Page, bind$0040.PageSize, bind$0040.OrderBy, bind$0040.OrderDirection))) : ((bind$0040_1 = state.BookingSearchQuery, new BookingSearchQuery_6(map((x_1) => x_1.Id, state.Accounts), bind$0040_1.BookingNumber, bind$0040_1.BookingStatus, bind$0040_1.PlaceOfReceipt, bind$0040_1.Destination, bind$0040_1.BookingDateName, bind$0040_1.FromDate, bind$0040_1.ToDate, bind$0040_1.ShipperName, bind$0040_1.ConsigneeName, bind$0040_1.ModeOfTransport, bind$0040_1.ServiceLevel, 1, bind$0040_1.PageSize, bind$0040_1.OrderBy, bind$0040_1.OrderDirection))), (arg) => (new Msg(1, [new AsyncMsg$1(1, [arg]), msg.fields[1]]))), new Intent(1, [])];
            }
        case 10: {
            const newState_1 = new State(state.Accounts, state.User, BookingSearchQuery_get_Default(), state.PreviousBookingSearchQuery, state.Bookings, state.UserDataSources, (bind$0040_2 = state.OriginSearch, new State_1(bind$0040_2.Name, bind$0040_2.Disabled, bind$0040_2.Search, bind$0040_2.SearchDeferred, bind$0040_2.IsModalActive, bind$0040_2.SearchCriteria, void 0)), (bind$0040_3 = state.DestinationSearch, new State_1(bind$0040_3.Name, bind$0040_3.Disabled, bind$0040_3.Search, bind$0040_3.SearchDeferred, bind$0040_3.IsModalActive, bind$0040_3.SearchCriteria, void 0)), (bind$0040_4 = state.ConsigneeSearch, new State_1(bind$0040_4.Name, bind$0040_4.Disabled, bind$0040_4.Search, bind$0040_4.SearchDeferred, bind$0040_4.IsModalActive, bind$0040_4.SearchCriteria, void 0)), (bind$0040_5 = state.ShipperSearch, new State_1(bind$0040_5.Name, bind$0040_5.Disabled, bind$0040_5.Search, bind$0040_5.SearchDeferred, bind$0040_5.IsModalActive, bind$0040_5.SearchCriteria, void 0)), state.ExportBookings);
            return [newState_1, singleton((dispatch) => {
                dispatch(new Msg(1, [new AsyncMsg$1(0, []), false]));
            }), new Intent(0, [newState_1.BookingSearchQuery])];
        }
        case 2: {
            const patternInput = update_1(msg.fields[0], state.OriginSearch);
            const componentIntent = patternInput[2];
            const componentCmd = patternInput[1];
            return [new State(state.Accounts, state.User, state.BookingSearchQuery, state.PreviousBookingSearchQuery, state.Bookings, state.UserDataSources, patternInput[0], state.DestinationSearch, state.ConsigneeSearch, state.ShipperSearch, state.ExportBookings), (componentIntent.tag === 1) ? Cmd_map((Item_2) => (new Msg(2, [Item_2])), componentCmd) : Cmd_batch(ofArray([Cmd_map((Item_1) => (new Msg(2, [Item_1])), componentCmd), singleton((dispatch_1) => {
                dispatch_1(new Msg(3, [componentIntent.fields[0]]));
            })])), new Intent(1, [])];
        }
        case 3: {
            const newState_2 = new State(state.Accounts, state.User, (bind$0040_6 = state.BookingSearchQuery, new BookingSearchQuery_6(bind$0040_6.AccountIds, bind$0040_6.BookingNumber, bind$0040_6.BookingStatus, defaultArg((x_6 = ((x_4 = msg.fields[0], (x_4 == null) ? void 0 : ((x_2 = x_4, (value_2 = x_2.Value, (value_2 === defaultOf()) ? void 0 : some(value_2)))))), (x_6 == null) ? void 0 : toString(value_507(x_6))), String_defaultValue()), bind$0040_6.Destination, bind$0040_6.BookingDateName, bind$0040_6.FromDate, bind$0040_6.ToDate, bind$0040_6.ShipperName, bind$0040_6.ConsigneeName, bind$0040_6.ModeOfTransport, bind$0040_6.ServiceLevel, bind$0040_6.Page, bind$0040_6.PageSize, bind$0040_6.OrderBy, bind$0040_6.OrderDirection)), state.PreviousBookingSearchQuery, state.Bookings, state.UserDataSources, state.OriginSearch, state.DestinationSearch, state.ConsigneeSearch, state.ShipperSearch, state.ExportBookings);
            return [newState_2, Cmd_none(), new Intent(0, [newState_2.BookingSearchQuery])];
        }
        case 4: {
            const patternInput_1 = update_1(msg.fields[0], state.DestinationSearch);
            const componentIntent_1 = patternInput_1[2];
            const componentCmd_1 = patternInput_1[1];
            return [new State(state.Accounts, state.User, state.BookingSearchQuery, state.PreviousBookingSearchQuery, state.Bookings, state.UserDataSources, state.OriginSearch, patternInput_1[0], state.ConsigneeSearch, state.ShipperSearch, state.ExportBookings), (componentIntent_1.tag === 1) ? Cmd_map((Item_4) => (new Msg(4, [Item_4])), componentCmd_1) : Cmd_batch(ofArray([Cmd_map((Item_3) => (new Msg(4, [Item_3])), componentCmd_1), singleton((dispatch_2) => {
                dispatch_2(new Msg(5, [componentIntent_1.fields[0]]));
            })])), new Intent(1, [])];
        }
        case 5: {
            const newState_3 = new State(state.Accounts, state.User, (bind$0040_7 = state.BookingSearchQuery, new BookingSearchQuery_6(bind$0040_7.AccountIds, bind$0040_7.BookingNumber, bind$0040_7.BookingStatus, bind$0040_7.PlaceOfReceipt, defaultArg((x_11 = ((x_9 = msg.fields[0], (x_9 == null) ? void 0 : ((x_7 = x_9, (value_6 = x_7.Value, (value_6 === defaultOf()) ? void 0 : some(value_6)))))), (x_11 == null) ? void 0 : toString(value_507(x_11))), String_defaultValue()), bind$0040_7.BookingDateName, bind$0040_7.FromDate, bind$0040_7.ToDate, bind$0040_7.ShipperName, bind$0040_7.ConsigneeName, bind$0040_7.ModeOfTransport, bind$0040_7.ServiceLevel, bind$0040_7.Page, bind$0040_7.PageSize, bind$0040_7.OrderBy, bind$0040_7.OrderDirection)), state.PreviousBookingSearchQuery, state.Bookings, state.UserDataSources, state.OriginSearch, state.DestinationSearch, state.ConsigneeSearch, state.ShipperSearch, state.ExportBookings);
            return [newState_3, Cmd_none(), new Intent(0, [newState_3.BookingSearchQuery])];
        }
        case 6: {
            const patternInput_2 = update_1(msg.fields[0], state.ConsigneeSearch);
            const componentIntent_2 = patternInput_2[2];
            const componentCmd_2 = patternInput_2[1];
            return [new State(state.Accounts, state.User, state.BookingSearchQuery, state.PreviousBookingSearchQuery, state.Bookings, state.UserDataSources, state.OriginSearch, state.DestinationSearch, patternInput_2[0], state.ShipperSearch, state.ExportBookings), (componentIntent_2.tag === 1) ? Cmd_map((Item_6) => (new Msg(6, [Item_6])), componentCmd_2) : Cmd_batch(ofArray([Cmd_map((Item_5) => (new Msg(6, [Item_5])), componentCmd_2), singleton((dispatch_3) => {
                dispatch_3(new Msg(7, [componentIntent_2.fields[0]]));
            })])), new Intent(1, [])];
        }
        case 7: {
            const newState_4 = new State(state.Accounts, state.User, (bind$0040_8 = state.BookingSearchQuery, new BookingSearchQuery_6(bind$0040_8.AccountIds, bind$0040_8.BookingNumber, bind$0040_8.BookingStatus, bind$0040_8.PlaceOfReceipt, bind$0040_8.Destination, bind$0040_8.BookingDateName, bind$0040_8.FromDate, bind$0040_8.ToDate, bind$0040_8.ShipperName, defaultArg((x_14 = msg.fields[0], (x_14 == null) ? void 0 : ((x_12 = x_14, x_12.DisplayValue))), String_defaultValue()), bind$0040_8.ModeOfTransport, bind$0040_8.ServiceLevel, bind$0040_8.Page, bind$0040_8.PageSize, bind$0040_8.OrderBy, bind$0040_8.OrderDirection)), state.PreviousBookingSearchQuery, state.Bookings, state.UserDataSources, state.OriginSearch, state.DestinationSearch, state.ConsigneeSearch, state.ShipperSearch, state.ExportBookings);
            return [newState_4, Cmd_none(), new Intent(0, [newState_4.BookingSearchQuery])];
        }
        case 8: {
            const patternInput_3 = update_1(msg.fields[0], state.ShipperSearch);
            const componentIntent_3 = patternInput_3[2];
            const componentCmd_3 = patternInput_3[1];
            return [new State(state.Accounts, state.User, state.BookingSearchQuery, state.PreviousBookingSearchQuery, state.Bookings, state.UserDataSources, state.OriginSearch, state.DestinationSearch, state.ConsigneeSearch, patternInput_3[0], state.ExportBookings), (componentIntent_3.tag === 1) ? Cmd_map((Item_8) => (new Msg(8, [Item_8])), componentCmd_3) : Cmd_batch(ofArray([Cmd_map((Item_7) => (new Msg(8, [Item_7])), componentCmd_3), singleton((dispatch_4) => {
                dispatch_4(new Msg(9, [componentIntent_3.fields[0]]));
            })])), new Intent(1, [])];
        }
        case 9: {
            const newState_5 = new State(state.Accounts, state.User, (bind$0040_9 = state.BookingSearchQuery, new BookingSearchQuery_6(bind$0040_9.AccountIds, bind$0040_9.BookingNumber, bind$0040_9.BookingStatus, bind$0040_9.PlaceOfReceipt, bind$0040_9.Destination, bind$0040_9.BookingDateName, bind$0040_9.FromDate, bind$0040_9.ToDate, defaultArg((x_17 = msg.fields[0], (x_17 == null) ? void 0 : ((x_15 = x_17, x_15.DisplayValue))), String_defaultValue()), bind$0040_9.ConsigneeName, bind$0040_9.ModeOfTransport, bind$0040_9.ServiceLevel, bind$0040_9.Page, bind$0040_9.PageSize, bind$0040_9.OrderBy, bind$0040_9.OrderDirection)), state.PreviousBookingSearchQuery, state.Bookings, state.UserDataSources, state.OriginSearch, state.DestinationSearch, state.ConsigneeSearch, state.ShipperSearch, state.ExportBookings);
            return [newState_5, Cmd_none(), new Intent(0, [newState_5.BookingSearchQuery])];
        }
        case 11:
            if (msg.fields[0].tag === 1) {
                return [new State(state.Accounts, state.User, state.BookingSearchQuery, state.PreviousBookingSearchQuery, state.Bookings, state.UserDataSources, state.OriginSearch, state.DestinationSearch, state.ConsigneeSearch, state.ShipperSearch, new Deferred$1(2, [msg.fields[0].fields[0]])), (msg.fields[0].fields[0].tag === 1) ? Alert_error(Alert_title("Export Track And Trace", Alert_text(msg.fields[0].fields[0].fields[0]))) : Cmd_none(), new Intent(1, [])];
            }
            else if (equals_1(state.ExportBookings, new Deferred$1(1, [])) ? true : (state.Accounts.length === 0)) {
                return [state, Cmd_none(), new Intent(1, [])];
            }
            else {
                return [new State(state.Accounts, state.User, state.BookingSearchQuery, state.PreviousBookingSearchQuery, state.Bookings, state.UserDataSources, state.OriginSearch, state.DestinationSearch, state.ConsigneeSearch, state.ShipperSearch, new Deferred$1(1, [])), Cmd_OfAsyncWith_perform((computation_1) => {
                    startImmediate(computation_1);
                }, export$, (bind$0040_10 = state.PreviousBookingSearchQuery, new BookingSearchQuery_6(map((x_18) => x_18.Id, state.Accounts), bind$0040_10.BookingNumber, bind$0040_10.BookingStatus, bind$0040_10.PlaceOfReceipt, bind$0040_10.Destination, bind$0040_10.BookingDateName, bind$0040_10.FromDate, bind$0040_10.ToDate, bind$0040_10.ShipperName, bind$0040_10.ConsigneeName, bind$0040_10.ModeOfTransport, bind$0040_10.ServiceLevel, bind$0040_10.Page, bind$0040_10.PageSize, bind$0040_10.OrderBy, bind$0040_10.OrderDirection)), (arg_2) => (new Msg(11, [new AsyncMsg$1(1, [arg_2])]))), new Intent(1, [])];
            }
        case 12:
            return [new State(state.Accounts, state.User, state.BookingSearchQuery, (bind$0040_11 = state.PreviousBookingSearchQuery, new BookingSearchQuery_6(bind$0040_11.AccountIds, bind$0040_11.BookingNumber, bind$0040_11.BookingStatus, bind$0040_11.PlaceOfReceipt, bind$0040_11.Destination, bind$0040_11.BookingDateName, bind$0040_11.FromDate, bind$0040_11.ToDate, bind$0040_11.ShipperName, bind$0040_11.ConsigneeName, bind$0040_11.ModeOfTransport, bind$0040_11.ServiceLevel, msg.fields[0], bind$0040_11.PageSize, bind$0040_11.OrderBy, bind$0040_11.OrderDirection)), state.Bookings, state.UserDataSources, state.OriginSearch, state.DestinationSearch, state.ConsigneeSearch, state.ShipperSearch, state.ExportBookings), singleton((dispatch_5) => {
                dispatch_5(new Msg(1, [new AsyncMsg$1(0, []), true]));
            }), new Intent(1, [])];
        default:
            return [new State(state.Accounts, state.User, msg.fields[0](state.BookingSearchQuery), state.PreviousBookingSearchQuery, state.Bookings, state.UserDataSources, state.OriginSearch, state.DestinationSearch, state.ConsigneeSearch, state.ShipperSearch, state.ExportBookings), Cmd_none(), new Intent(1, [])];
    }
}

export function render(state, dispatch) {
    let elems_10, elems_9, elems, elems_1, matchValue, elems_5, elems_4, elems_3, elems_2, elems_8, children, elems_34, elems_33, elems_12, elems_11, value_125, elems_14, elems_13, elems_17, elems_16, value_165, elems_15, elems_18, elems_19, elems_22, elems_21, value_209, elems_20, elems_23, value_237, elems_24, value_260, elems_25, elems_26, elems_29, elems_28, value_302, elems_27, elems_32, elems_31, value_331, elems_30, elems_55, elems_57;
    const header = createElement("div", createObj(ofArray([["className", "box"], (elems_10 = [createElement("div", createObj(ofArray([["className", join(" ", ["columns", "is-align-items-center"])], (elems_9 = [createElement("div", createObj(ofArray([["className", "column"], (elems = [createElement("span", {
        className: join(" ", ["has-text-weight-semibold", "has-text-link", "is-size-5"]),
        children: "Bookings",
    })], ["children", Interop_reactApi.Children.toArray(Array.from(elems))])]))), createElement("div", createObj(ofArray([["className", "column"], (elems_1 = [createElement("div", {
        className: join(" ", ["has-text-weight-semibold"]),
        children: (matchValue = state.Bookings, (matchValue.tag === 1) ? "Searching..." : ((matchValue.tag === 2) ? ((matchValue.fields[0].tag === 0) ? ((matchValue.fields[0].fields[0].Data.length === 0) ? "0 bookings found" : (`${formatInt(~~toInt32(matchValue.fields[0].fields[0].TotalCount))} bookings found | showing page ${formatInt(matchValue.fields[0].fields[0].Page)} of ${formatInt(matchValue.fields[0].fields[0].NumberOfPages)}`)) : "") : "")),
    })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_1))])]))), createElement("div", createObj(ofArray([["className", join(" ", ["column", "is-narrow", "is-hidden"])], (elems_5 = [createElement("div", createObj(ofArray([["className", join(" ", ["is-flex", "is-flex-wrap-wrap", "is-align-items-center"])], (elems_4 = [createElement("div", {
        className: join(" ", ["has-text-weight-semibold", "mr-2"]),
        children: "Sort By ",
    }), createElement("div", createObj(ofArray([["className", "select"], (elems_3 = [createElement("select", createObj(ofArray([["disabled", true], ["className", join(" ", ["is-small", "is-fullwidth"])], (elems_2 = [createElement("option", {
        value: true,
        children: "Booking Date",
    }), createElement("option", {
        value: true,
        children: "Pickup Date",
    }), createElement("option", {
        value: true,
        children: "Origin",
    }), createElement("option", {
        value: true,
        children: "Destination",
    }), createElement("option", {
        value: true,
        children: "Mode of Transport",
    })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_2))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_3))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_4))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_5))])]))), createElement("div", createObj(ofArray([["className", join(" ", ["column", "is-narrow"])], (elems_8 = [(children = toList(delay(() => {
        let elems_6;
        return append(singleton_1(createElement("button", createObj(ofArray([["className", join(" ", ["button", "is-small", "ml-2"])], ["disabled", !hasPreviousPage(state)], ["type", "button"], (elems_6 = [createElement("span", {
            className: join(" ", ["material-icons", "is-small"]),
            children: "navigate_before",
        }), createElement("span", {
            className: "is-sr-only",
            children: "Previous",
        })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_6))]), ["onClick", (_arg) => {
            if (hasPreviousPage(state)) {
                dispatch(new Msg(12, [state.PreviousBookingSearchQuery.Page - 1]));
            }
        }]])))), delay(() => {
            let elems_7;
            return append(singleton_1(createElement("button", createObj(ofArray([["className", join(" ", ["button", "is-small", "ml-2"])], ["type", "button"], ["disabled", !hasNextPage(state)], (elems_7 = [createElement("span", {
                className: join(" ", ["material-icons", "is-small"]),
                children: "navigate_next",
            }), createElement("span", {
                className: "is-sr-only",
                children: "Next",
            })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_7))]), ["onClick", (_arg_1) => {
                if (hasNextPage(state)) {
                    dispatch(new Msg(12, [state.PreviousBookingSearchQuery.Page + 1]));
                }
            }]])))), delay(() => (state.User.CanExportBookings ? singleton_1(createElement("button", {
                className: join(" ", ["button", "is-small", "ml-2"]),
                type: "button",
                children: (state.ExportBookings.tag === 1) ? "Exporting..." : "Export",
                disabled: equals_1(state.ExportBookings, new Deferred$1(1, [])) ? true : isExportDisabled(state),
                onClick: (_arg_2) => {
                    dispatch(new Msg(11, [new AsyncMsg$1(0, [])]));
                },
            })) : empty())));
        }));
    })), createElement("div", {
        children: Interop_reactApi.Children.toArray(Array.from(children)),
    }))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_8))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_9))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_10))])])));
    const searchForm = createElement("div", createObj(ofArray([["onSubmit", (e) => {
        e.preventDefault();
        if (equals_1(state.Bookings, new Deferred$1(1, [])) ? true : (state.Accounts.length === 0)) {
        }
        else {
            dispatch(new Msg(1, [new AsyncMsg$1(0, []), false]));
        }
    }], ["className", join(" ", ["column", "is-narrow"])], (elems_34 = [createElement("form", createObj(ofArray([["className", "box"], ["style", {
        minWidth: 300 + "px",
    }], (elems_33 = [createElement("div", createObj(ofArray([["className", "block"], (elems_12 = [createElement("div", createObj(ofArray([["className", join(" ", ["is-flex", "is-justify-content-space-between", "is-align-items-center", "mb-1"])], (elems_11 = [createElement("label", {
        className: "has-text-weight-semibold",
        for: "bookingNumber",
        children: "Booking Number",
    })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_11))])]))), createElement("input", createObj(ofArray([["className", join(" ", ["input", "is-small", "is-fullwidth"])], ["id", "bookingNumber"], ["name", "bookingNumber"], ["type", "text"], ["maxLength", 100], (value_125 = state.BookingSearchQuery.BookingNumber, ["ref", (e_1) => {
        if (!(e_1 == null) && !equals_1(e_1.value, value_125)) {
            e_1.value = value_125;
        }
    }]), ["placeholder", "Booking Number..."], ["onChange", (ev) => {
        const value_129 = ev.target.value;
        dispatch(new Msg(0, [(query) => (new BookingSearchQuery_6(query.AccountIds, value_129, query.BookingStatus, query.PlaceOfReceipt, query.Destination, query.BookingDateName, query.FromDate, query.ToDate, query.ShipperName, query.ConsigneeName, query.ModeOfTransport, query.ServiceLevel, query.Page, query.PageSize, query.OrderBy, query.OrderDirection))]));
    }]])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_12))])]))), createElement("div", createObj(ofArray([["className", join(" ", ["block", "is-hidden"])], (elems_14 = [createElement("div", createObj(ofArray([["className", join(" ", ["is-flex", "is-justify-content-space-between", "is-align-items-center", "mb-1"])], (elems_13 = [createElement("label", {
        className: "has-text-weight-semibold",
        for: "bookingNumber",
        children: "Tracking Number",
    })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_13))])]))), createElement("input", {
        className: join(" ", ["input", "is-small", "is-fullwidth"]),
        id: "bookingNumber",
        name: "bookingNumber",
        type: "text",
        placeholder: "Tracking Number...",
    })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_14))])]))), createElement("div", createObj(ofArray([["className", "block"], (elems_17 = [createElement("label", {
        className: join(" ", ["is-block", "mb-1", "has-text-weight-semibold"]),
        for: "status",
        children: "Status",
    }), createElement("div", createObj(ofArray([["className", join(" ", ["select", "is-small", "is-fullwidth"])], (elems_16 = [createElement("select", createObj(ofArray([["className", ""], ["id", "status"], ["name", "status"], (value_165 = state.BookingSearchQuery.BookingStatus, ["ref", (e_2) => {
        if (!(e_2 == null) && !equals_1(e_2.value, value_165)) {
            e_2.value = value_165;
        }
    }]), (elems_15 = toList(delay(() => append(singleton_1(createElement("option", {
        value: "Any",
        children: "Any",
    })), delay(() => map_1((status) => createElement("option", {
        value: status.StatusName,
        children: status.StatusName,
    }), state.UserDataSources.BookingStatuses))))), ["children", Interop_reactApi.Children.toArray(Array.from(elems_15))]), ["onChange", (e_3) => {
        const element = e_3.target;
        dispatch(new Msg(0, [(query_1) => (new BookingSearchQuery_6(query_1.AccountIds, query_1.BookingNumber, element.value, query_1.PlaceOfReceipt, query_1.Destination, query_1.BookingDateName, query_1.FromDate, query_1.ToDate, query_1.ShipperName, query_1.ConsigneeName, query_1.ModeOfTransport, query_1.ServiceLevel, 0, query_1.PageSize, query_1.OrderBy, query_1.OrderDirection))]));
    }]])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_16))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_17))])]))), createElement("div", createObj(ofArray([["className", join(" ", ["block"])], ["onKeyPress", (keyPress) => {
        if (keyPress.key === "Enter") {
            keyPress.preventDefault();
        }
    }], (elems_18 = [createElement("label", {
        className: join(" ", ["is-block", "mb-1", "has-text-weight-semibold"]),
        for: "origin",
        children: "Ship From",
    }), render_1(state.OriginSearch, (arg_1) => {
        dispatch(new Msg(2, [arg_1]));
    })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_18))])]))), createElement("div", createObj(ofArray([["className", join(" ", ["block"])], ["onKeyPress", (keyPress_1) => {
        if (keyPress_1.key === "Enter") {
            keyPress_1.preventDefault();
        }
    }], (elems_19 = [createElement("label", {
        className: join(" ", ["is-block", "mb-1", "has-text-weight-semibold"]),
        for: "destination",
        children: "Ship To",
    }), render_1(state.DestinationSearch, (arg_2) => {
        dispatch(new Msg(4, [arg_2]));
    })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_19))])]))), createElement("div", createObj(ofArray([["className", "block"], (elems_22 = [createElement("label", {
        className: join(" ", ["is-block", "mb-1", "has-text-weight-semibold"]),
        for: "shipmentDate",
        children: "Date",
    }), createElement("div", createObj(ofArray([["className", join(" ", ["select", "is-small", "is-fullwidth"])], (elems_21 = [createElement("select", createObj(ofArray([["className", ""], ["id", "Date"], ["name", "Date"], (value_209 = state.BookingSearchQuery.BookingDateName, ["ref", (e_4) => {
        if (!(e_4 == null) && !equals_1(e_4.value, value_209)) {
            e_4.value = value_209;
        }
    }]), (elems_20 = toList(delay(() => append(singleton_1(createElement("option", {
        value: "",
        children: "Select...",
    })), delay(() => map_1((date) => createElement("option", {
        value: date.BookingDate,
        children: date.BookingDate,
    }), state.UserDataSources.BookingDates))))), ["children", Interop_reactApi.Children.toArray(Array.from(elems_20))]), ["onChange", (e_5) => {
        const element_1 = e_5.target;
        dispatch(new Msg(0, [(query_2) => (isNullOrWhiteSpace(element_1.value) ? (new BookingSearchQuery_6(query_2.AccountIds, query_2.BookingNumber, query_2.BookingStatus, query_2.PlaceOfReceipt, query_2.Destination, element_1.value, defaultOf_1(), defaultOf_1(), query_2.ShipperName, query_2.ConsigneeName, query_2.ModeOfTransport, query_2.ServiceLevel, query_2.Page, query_2.PageSize, query_2.OrderBy, query_2.OrderDirection)) : (new BookingSearchQuery_6(query_2.AccountIds, query_2.BookingNumber, query_2.BookingStatus, query_2.PlaceOfReceipt, query_2.Destination, element_1.value, query_2.FromDate, query_2.ToDate, query_2.ShipperName, query_2.ConsigneeName, query_2.ModeOfTransport, query_2.ServiceLevel, query_2.Page, query_2.PageSize, query_2.OrderBy, query_2.OrderDirection)))]));
    }]])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_21))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_22))])]))), createElement("div", createObj(ofArray([["className", "block"], (elems_23 = [createElement("label", {
        className: join(" ", ["is-block", "mb-1", "has-text-weight-semibold"]),
        for: "fromDate",
        children: "From",
    }), createElement("input", createObj(ofArray([["className", join(" ", ["input", "is-small", "is-fullwidth"])], ["id", "fromDate"], ["name", "fromDate"], ["disabled", isNullOrWhiteSpace(state.BookingSearchQuery.BookingDateName) ? true : (state.BookingSearchQuery.BookingDateName === "Select..")], (value_237 = toDateTimeOffsetInput(state.BookingSearchQuery.FromDate), ["ref", (e_6) => {
        if (!(e_6 == null) && !equals_1(e_6.value, value_237)) {
            e_6.value = value_237;
        }
    }]), ["type", "date"], ["min", toString_1(minValue(), "yyyy-MM-dd")], ["max", toString_1(maxValue(), "yyyy-MM-dd")], ["onChange", (e_7) => {
        const element_2 = e_7.target;
        dispatch(new Msg(0, [(query_3) => (new BookingSearchQuery_6(query_3.AccountIds, query_3.BookingNumber, query_3.BookingStatus, query_3.PlaceOfReceipt, query_3.Destination, query_3.BookingDateName, isNullOrWhiteSpace(element_2.value) ? defaultOf_1() : toNullable(DateTimeOffset_fromString(element_2.value)), query_3.ToDate, query_3.ShipperName, query_3.ConsigneeName, query_3.ModeOfTransport, query_3.ServiceLevel, 0, query_3.PageSize, query_3.OrderBy, query_3.OrderDirection))]));
    }]])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_23))])]))), createElement("div", createObj(ofArray([["className", "block"], (elems_24 = [createElement("label", {
        className: join(" ", ["is-block", "mb-1", "has-text-weight-semibold"]),
        for: "toDate",
        children: "To",
    }), createElement("input", createObj(ofArray([["className", join(" ", ["input", "is-small", "is-fullwidth"])], ["id", "toDate"], ["name", "toDate"], (value_260 = toDateTimeOffsetInput(state.BookingSearchQuery.ToDate), ["ref", (e_8) => {
        if (!(e_8 == null) && !equals_1(e_8.value, value_260)) {
            e_8.value = value_260;
        }
    }]), ["disabled", isNullOrWhiteSpace(state.BookingSearchQuery.BookingDateName) ? true : (state.BookingSearchQuery.BookingDateName === "Select..")], ["type", "date"], ["min", toString_1(minValue(), "yyyy-MM-dd")], ["max", toString_1(maxValue(), "yyyy-MM-dd")], ["onChange", (e_9) => {
        const element_3 = e_9.target;
        dispatch(new Msg(0, [(query_4) => (new BookingSearchQuery_6(query_4.AccountIds, query_4.BookingNumber, query_4.BookingStatus, query_4.PlaceOfReceipt, query_4.Destination, query_4.BookingDateName, query_4.FromDate, isNullOrWhiteSpace(element_3.value) ? defaultOf_1() : toNullable(DateTimeOffset_fromString(element_3.value)), query_4.ShipperName, query_4.ConsigneeName, query_4.ModeOfTransport, query_4.ServiceLevel, 0, query_4.PageSize, query_4.OrderBy, query_4.OrderDirection))]));
    }]])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_24))])]))), createElement("div", createObj(ofArray([["className", join(" ", ["block"])], ["onKeyPress", (keyPress_2) => {
        if (keyPress_2.key === "Enter") {
            keyPress_2.preventDefault();
        }
    }], (elems_25 = [createElement("label", {
        className: join(" ", ["is-block", "mb-1", "has-text-weight-semibold"]),
        for: "shipper",
        children: "Shipper",
    }), render_1(state.ShipperSearch, (arg_3) => {
        dispatch(new Msg(8, [arg_3]));
    })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_25))])]))), createElement("div", createObj(ofArray([["className", join(" ", ["block"])], ["onKeyPress", (keyPress_3) => {
        if (keyPress_3.key === "Enter") {
            keyPress_3.preventDefault();
        }
    }], (elems_26 = [createElement("label", {
        className: join(" ", ["is-block", "mb-1", "has-text-weight-semibold"]),
        for: "consignee",
        children: "Consignee",
    }), render_1(state.ConsigneeSearch, (arg_4) => {
        dispatch(new Msg(6, [arg_4]));
    })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_26))])]))), createElement("div", createObj(ofArray([["className", "block"], (elems_29 = [createElement("label", {
        className: join(" ", ["is-block", "mb-1", "has-text-weight-semibold"]),
        for: "mot",
        children: "Mode Of Transport",
    }), createElement("div", createObj(ofArray([["className", join(" ", ["select", "is-small", "is-fullwidth"])], (elems_28 = [createElement("select", createObj(ofArray([["className", join(" ", ["input", "is-small", "is-fullwidth"])], ["id", "mot"], ["name", "mot"], (value_302 = state.BookingSearchQuery.ModeOfTransport, ["ref", (e_10) => {
        if (!(e_10 == null) && !equals_1(e_10.value, value_302)) {
            e_10.value = value_302;
        }
    }]), (elems_27 = toList(delay(() => append(singleton_1(createElement("option", {
        value: "Any",
        children: "Any",
    })), delay(() => map_1((mot) => createElement("option", {
        value: mot.ModeOfTransport,
        children: mot.ModeOfTransport,
    }), state.UserDataSources.ModesOfTransport))))), ["children", Interop_reactApi.Children.toArray(Array.from(elems_27))]), ["onChange", (e_11) => {
        const element_4 = e_11.target;
        dispatch(new Msg(0, [(query_5) => (new BookingSearchQuery_6(query_5.AccountIds, query_5.BookingNumber, query_5.BookingStatus, query_5.PlaceOfReceipt, query_5.Destination, query_5.BookingDateName, query_5.FromDate, query_5.ToDate, query_5.ShipperName, query_5.ConsigneeName, element_4.value, "", query_5.Page, query_5.PageSize, query_5.OrderBy, query_5.OrderDirection))]));
    }]])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_28))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_29))])]))), createElement("div", createObj(ofArray([["className", "block"], (elems_32 = [createElement("label", {
        className: join(" ", ["is-block", "mb-1", "has-text-weight-semibold"]),
        for: "serviceLevel",
        children: "Service Level",
    }), createElement("div", createObj(ofArray([["className", join(" ", ["select", "is-small", "is-fullwidth"])], (elems_31 = [createElement("select", createObj(ofArray([["className", join(" ", ["input", "is-small", "is-fullwidth"])], ["disabled", isNullOrWhiteSpace(state.BookingSearchQuery.ModeOfTransport) ? true : (compare_1(state.BookingSearchQuery.ModeOfTransport, "Any", 5) === 0)], ["id", "serviceLevel"], ["name", "serviceLevel"], (value_331 = state.BookingSearchQuery.ServiceLevel, ["ref", (e_12) => {
        if (!(e_12 == null) && !equals_1(e_12.value, value_331)) {
            e_12.value = value_331;
        }
    }]), (elems_30 = toList(delay(() => append(singleton_1(createElement("option", {
        value: "",
        children: "Any",
    })), delay(() => map_1((serviceLevel) => createElement("option", {
        value: serviceLevel.Name,
        children: serviceLevel.Name,
    }), serviceLevelSearch(state)))))), ["children", Interop_reactApi.Children.toArray(Array.from(elems_30))]), ["disabled", isNullOrWhiteSpace(state.BookingSearchQuery.ModeOfTransport) ? true : (compare_1(state.BookingSearchQuery.ModeOfTransport, "Any", 5) === 0)], ["onChange", (e_13) => {
        const element_5 = e_13.target;
        dispatch(new Msg(0, [(query_6) => (new BookingSearchQuery_6(query_6.AccountIds, query_6.BookingNumber, query_6.BookingStatus, query_6.PlaceOfReceipt, query_6.Destination, query_6.BookingDateName, query_6.FromDate, query_6.ToDate, query_6.ShipperName, query_6.ConsigneeName, query_6.ModeOfTransport, element_5.value, query_6.Page, query_6.PageSize, query_6.OrderBy, query_6.OrderDirection))]));
    }]])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_31))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_32))])]))), createElement("hr", {
        className: "my-4",
    }), createElement("button", {
        className: join(" ", toList(delay(() => append(singleton_1("block"), delay(() => append(singleton_1("button"), delay(() => append(singleton_1("is-small"), delay(() => append(singleton_1("is-primary"), delay(() => append(singleton_1("is-fullwidth"), delay(() => (equals_1(state.Bookings, new Deferred$1(1, [])) ? singleton_1("is-loading") : empty())))))))))))))),
        type: "submit",
        children: "Search",
    }), createElement("button", {
        className: join(" ", toList(delay(() => append(singleton_1("block"), delay(() => append(singleton_1("button"), delay(() => append(singleton_1("is-small"), delay(() => append(singleton_1("is-fullwidth"), delay(() => (equals_1(state.Bookings, new Deferred$1(1, [])) ? singleton_1("is-loading") : empty())))))))))))),
        type: "button",
        onClick: (_arg_3) => {
            dispatch(new Msg(10, []));
        },
        children: "Clear",
    })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_33))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_34))])])));
    const searchTable = createElement("div", createObj(ofArray([["className", "column"], ["style", {
        overflow: "auto",
    }], (elems_55 = toList(delay(() => {
        let elems_54, elems_53, elems_52, children_2, elems_35, elems_51;
        const matchValue_8 = state.Bookings;
        let matchResult, pagedData_1;
        switch (matchValue_8.tag) {
            case 1: {
                matchResult = 0;
                break;
            }
            case 2: {
                if (matchValue_8.fields[0].tag === 0) {
                    if (compare(matchValue_8.fields[0].fields[0].TotalCount, toInt64(fromInt32(0))) > 0) {
                        matchResult = 1;
                        pagedData_1 = matchValue_8.fields[0].fields[0];
                    }
                    else {
                        matchResult = 2;
                    }
                }
                else {
                    matchResult = 2;
                }
                break;
            }
            default:
                matchResult = 2;
        }
        switch (matchResult) {
            case 0:
                return singleton_1(render_2());
            case 1:
                return singleton_1(createElement("div", createObj(ofArray([["className", join(" ", ["box"])], (elems_54 = [createElement("div", createObj(ofArray([["className", join(" ", ["table-container", "table-has-fixed-header"])], (elems_53 = [createElement("table", createObj(ofArray([["className", join(" ", ["table", "is-narrow", "is-fullwidth"])], (elems_52 = [(children_2 = singleton(createElement("tr", createObj(ofArray([["className", ""], (elems_35 = [createElement("th", {
                    className: "p-2",
                    children: "Booking Number",
                }), createElement("th", {
                    className: "p-2",
                    children: "Mode of Transport",
                }), createElement("th", {
                    className: "p-2",
                    children: "Ship From",
                }), createElement("th", {
                    className: "p-2",
                    children: "Ship To",
                }), createElement("th", {
                    className: "p-2",
                    children: "Shipper",
                }), createElement("th", {
                    className: "p-2",
                    children: "Consignee",
                }), createElement("th", {
                    className: "p-2",
                    children: "Weight",
                }), createElement("th", {
                    className: "p-2",
                    children: "Volume",
                }), createElement("th", {
                    className: "p-2",
                    children: "Booking Date",
                }), createElement("th", {
                    className: "p-2",
                    children: "Pickup Date",
                }), createElement("th", {
                    className: "p-2",
                    children: "Status",
                })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_35))])])))), createElement("thead", {
                    children: Interop_reactApi.Children.toArray(Array.from(children_2)),
                })), createElement("tbody", createObj(ofArray([["className", ""], (elems_51 = toList(delay(() => map_1((booking) => {
                    let elems_50, elems_37, elems_36, elems_40, elems_39, elems_38, children_4, elems_42, elems_41, elems_44, elems_43, elems_46, elems_45, elems_48, elems_47, elems_49;
                    return createElement("tr", createObj(ofArray([["className", ""], (elems_50 = [createElement("td", createObj(ofArray([["className", "p-2"], (elems_37 = [createElement("div", createObj(ofArray([["className", join(" ", ["is-flex", "is-flex-wrap-wrap", "is-align-content-center", "is-align-items-center", "is-underlined"])], (elems_36 = [createElement("a", {
                        className: join(" ", ["is-link", "is-underlined"]),
                        href: RouterModule_encodeParts(ofArray(["/booking-details", booking.Id]), 1),
                        children: booking.BookingNumber,
                    })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_36))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_37))])]))), createElement("td", createObj(ofArray([["className", join(" ", ["p-2"])], (elems_40 = [createElement("div", createObj(ofArray([["className", join(" ", ["column", "is-flex"])], (elems_39 = [createElement("div", createObj(ofArray([["className", join(" ", ["is-flex", "is-flex-wrap-wrap", "is-align-content-center", "is-align-items-center"])], (elems_38 = [ModeOfTransportIcon_render(new IconType(0, []), booking.ModeOfTransportIcon)], ["children", Interop_reactApi.Children.toArray(Array.from(elems_38))])]))), (children_4 = ofArray([createElement("a", {
                        href: RouterModule_encodeParts(ofArray(["/shipment-details", (booking.ShipmentId != null) ? value_507(booking.ShipmentId) : ""]), 1),
                        children: booking.ShipmentNumber,
                    }), createElement("div", {
                        children: [booking.ServiceType],
                    }), createElement("div", {
                        children: [booking.ServiceLevel],
                    })]), createElement("div", {
                        children: Interop_reactApi.Children.toArray(Array.from(children_4)),
                    }))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_39))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_40))])]))), createElement("td", createObj(ofArray([["className", "p-2"], (elems_42 = [createElement("div", createObj(ofArray([["className", join(" ", ["is-flex", "is-flex-direction-column", "is-justify-content-space-between"])], (elems_41 = [createElement("span", {
                        children: [booking.PlaceOfReceipt],
                    })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_41))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_42))])]))), createElement("td", createObj(ofArray([["className", "p-2"], (elems_44 = [createElement("div", createObj(ofArray([["className", join(" ", ["is-flex", "is-flex-direction-column", "is-justify-content-space-between"])], (elems_43 = [createElement("span", {
                        children: [booking.Destination],
                    })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_43))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_44))])]))), createElement("td", createObj(ofArray([["className", "p-2"], (elems_46 = [createElement("div", createObj(ofArray([["className", join(" ", ["is-flex", "is-flex-direction-column", "is-justify-content-space-between"])], (elems_45 = toList(delay(() => append(singleton_1(createElement("span", {
                        children: [booking.Shipper],
                    })), delay(() => map_1((address) => createElement("span", {
                        children: [address],
                    }), booking.ShipperAddress))))), ["children", Interop_reactApi.Children.toArray(Array.from(elems_45))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_46))])]))), createElement("td", createObj(ofArray([["className", "p-2"], (elems_48 = [createElement("div", createObj(ofArray([["className", join(" ", ["is-flex", "is-flex-direction-column", "is-justify-content-space-between"])], (elems_47 = toList(delay(() => append(singleton_1(createElement("span", {
                        children: [booking.Consignee],
                    })), delay(() => map_1((address_1) => createElement("span", {
                        children: [address_1],
                    }), booking.ConsigneeAddress))))), ["children", Interop_reactApi.Children.toArray(Array.from(elems_47))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_48))])]))), createElement("td", {
                        className: "p-2",
                        children: formatDecimalWithUnits(booking.TotalWeightImperialUnit, booking.TotalWeightImperial),
                    }), createElement("td", {
                        className: "p-2",
                        children: formatDecimalWithUnits(booking.TotalVolumeImperialUnit, booking.TotalVolumeImperial),
                    }), createElement("td", {
                        className: "p-2",
                        children: DateTime_formatNullable(booking.BookingDate),
                    }), createElement("td", {
                        className: "p-2",
                        children: DateTime_formatNullable(booking.PickupDate),
                    }), createElement("td", createObj(ofArray([["className", "p-2"], (elems_49 = [createElement("span", {
                        className: join(" ", toList(delay(() => (!isNullOrWhiteSpace(booking.BookingStatus) ? append(singleton_1("tag"), delay(() => singleton_1(formatBookingStatusAsClass(booking.BookingStatus)))) : empty())))),
                        children: booking.BookingStatus,
                    })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_49))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_50))])])));
                }, pagedData_1.Data))), ["children", Interop_reactApi.Children.toArray(Array.from(elems_51))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_52))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_53))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_54))])]))));
            default:
                return singleton_1(defaultOf_2());
        }
    })), ["children", Interop_reactApi.Children.toArray(Array.from(elems_55))])])));
    const mainContent = createElement("div", {
        className: "columns",
        children: Interop_reactApi.Children.toArray([searchForm, searchTable]),
    });
    let missingAccountContent;
    const children_6 = singleton(createElement("h4", {
        className: join(" ", ["has-text-weight-semibold", "has-text-centered", "is-size-5"]),
        children: "Please select a client account",
    }));
    missingAccountContent = createElement("div", {
        children: Interop_reactApi.Children.toArray(Array.from(children_6)),
    });
    return createElement("div", createObj(ofArray([["className", join(" ", [])], (elems_57 = toList(delay(() => ((state.Accounts.length === 0) ? singleton_1(missingAccountContent) : append(singleton_1(header), delay(() => singleton_1(mainContent)))))), ["children", Interop_reactApi.Children.toArray(Array.from(elems_57))])])));
}

